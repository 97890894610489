<template>
  <!--
    The view for InstallationIps
  -->
  <div class="installationDetail_InstallationIp row">
    <div
      v-if="!authenticationHasRole('scope_customer')"
      class="col-12 col-lg-12 col-xl-6"
    >
      <Portlet
        title="Static IP"
        class="staticIp"
        icon="network-wired"
      >
        <template slot="buttons">
          <button
            class="btn btn-sm btn-primary float-right mt-2 mb-2"
            @click="showSidebarAddStatic"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t("add") }}
            </span>
          </button>
        </template>
        <InstallationIpList
          ref="staticList"
          :installation-id="installationId"
          :type="'static'"
          @reloadAuditLogs="reloadAuditLogs"
        />
      </Portlet>
    </div>
    <div
      :class="[
        'col-12 col-lg-12',
        {
          'col-xl-6': !authenticationHasRole('scope_customer'),
          'col-xl-12': authenticationHasRole('scope_customer'),
        },
      ]"
    >
      <Portlet
        title="Dynamic IP"
        class="dynamicIp"
        icon="network-wired"
      >
        <template slot="buttons">
          <button
            class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2"
            @click="addMyIp"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t("addMyIp") }}
            </span>
          </button>
          <button
            class="btn btn-sm btn-primary float-right mt-2 mb-2"
            @click="showSidebarAddDynamic"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t("add") }}
            </span>
          </button>
        </template>
        <InstallationIpList
          ref="dynamicList"
          :installation-id="installationId"
          :type="'dynamic'"
          @reloadAuditLogs="reloadAuditLogs"
        />
      </Portlet>
    </div>
    <DatabaseAuditLogList
      v-if="authenticationHasRole('scope_staff')"
      ref="databaseAuditLogList"
      class="col-12"
      :installation-id="installationId"
      :is-installation-ip="true"
      @reload="reload"
    />
    <SweetModal
      ref="confirmIp"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="userIp">
        {{ $t('addMyIpPrompt', {'0' : userIp}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmIp.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-success float-right mb-3"
        @click="addUserIp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('agree') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "InstallationDetailInstallationIp",
  components: {
    InstallationIpList : () => import('@/components/Installation/InstallationIpList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    SweetModal
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: 'InstallationIps'
    }
  },
  data () {
    return {
      userIp: null,
    };
  },
  methods: {
    async addUserIp () {
      const body = {
        installationId: this.installationId,
        type: 'dynamic',
        ipAddress: this.userIp,
        active: true,
        expiry: 1
      }
      
      await this.axios
        .post(`/Installation/InstallationIp?user=true`, body)
        .then(async (res) => {
          if (res) {
            this.$snotify.success(this.$t("ipAddedSuccessfully"));
            this.reload()
            this.$refs.confirmIp.close()
          }
        });
    },
    async addMyIp () {
      await fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        this.userIp = data.ip
        this.$refs.confirmIp.open();
      })
      .catch(error => {
        this.$snotify.error(
         'Error while getting IP. Try deactivating AdBlocker.'
        );
        this.error_clear();
        this.error_validate(error);
      });
    },
    showSidebarAddStatic () {
      this.$refs.staticList.showSidebarAdd();
    },
    showSidebarAddDynamic () {
      this.$refs.dynamicList.showSidebarAdd();
    },
    reloadAuditLogs () {
      if (this.$refs.databaseAuditLogList) {
        this.$refs.databaseAuditLogList.reloadAuditLogs();
      }
    },
    reload () {
      if (this.$refs.staticList) {
        this.$refs.staticList.reloadIpAddress();
      }

      if (this.$refs.dynamicList) {
        this.$refs.dynamicList.reloadIpAddress();
      }
    },
  }
}
</script>
